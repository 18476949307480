@import '~antd/dist/antd.css';

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  float: left;
  /* width: 120px; */
  height: 31px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.site-layout-background {
  background: #fff;
}

.diff-text-area {
  min-height: 70vh !important;
}

.app-content {
  min-height: 80vh !important;
}

#view-mode-radio-group {
  float: right;
  padding: 24px 24px;
}

@media (max-width: 575px) {
  .diff-text-area {
    min-height: 35vh !important;
  }
}

@media (max-width: 503px) {
  #view-mode-radio-group {
    float: right;
    padding: 0px 24px 24px 24px;
  }
}

.site-header {
  background: rgba(0,0,0,0.8);
}

.ant-page-header-heading-sub-title {
  margin-top: 3px;
}

#logo-diff {
  background: #951717;
  padding: 5px 5px;
}

#logo-dot {
  padding: 0px 2px;
}

#logo-fyi {
  background: #2a733c;
  padding: 5px 5px;
}

.css-cncyp1-word-diff {
  display: revert !important;
  padding: 0px !important;
}

body { 
  background-color: #f0f2f5;
}
